<template>
    <div class="bg re_sizing re_flex_center">
        <div class="content re_text_c re_sizing">
            <div>
                <img :src="info.photo" alt="头像" class="avatar">

                <div class="nickname re_ellipsis re_font_14 re_color_active">您的好友&nbsp;{{ info.nickName }}</div>
            </div>
            <!-- Phone input -->
            <div class="re_width100 re_margin_top_16">
                <input type="number" v-model="phone" class="input re_sizing" maxlength="11" placeholder="手机号码">
            </div>

            <!-- SMS code input -->
            <div class="re_width100 sms-group re_relative">
                <input type="text" v-model="smsCode" class="input re_sizing" maxlength="6" placeholder="6位验证码">
                <button :class="['sms-button', { 'disabled': counting }]" @click="getSmsCode" :disabled="counting">
                    {{ countText }}
                </button>
            </div>

            <!-- Submit button -->
            <button class="submit-button" @click="handleSubmit"></button>

            <!-- Agreement checkbox -->
            <div class="agreement re_flex_center">
                <img v-if="agreed" @click="agreed = !agreed" class="agree-icon" src="../../../assets/register/radio.png" alt="同意">
                <img v-else @click="agreed = !agreed" class="agree-icon" src="../../../assets/register/radio1.png" alt="同意">
                <label class="re_margin_l_8" for="agree">
                    <span class="re_color_light">阅读并同意</span>
                    <a class="re_color_active" href="https://www.kaishuihu.com/kshptyhxy.html" target="_blank">《用户服务协议》</a>
                    <span class="re_color_light">和</span>
                    <a class="re_color_active" href="https://www.kaishuihu.com/kshysqzc.html" target="_blank">《隐私政策》</a>
                </label>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
// import { userInviteShareWxInfo, getInfo } from './api.js'
import { ref, onMounted, computed } from 'vue';
import { showToast, showConfirmDialog } from 'vant';
import { sendRegisterSmsCode, codeRegister, shareConfig, briefInfo } from './api.js'
const router = useRouter()

const phone = ref('')
const smsCode = ref('')
const agreed = ref(false)
const counting = ref(false)
const counter = ref(60)
const env = ref('')

onMounted(() => {
    
    if(process.env.VUE_APP_API_URL == 'https://llcbdev.lanlingcb.com/dsgc'){
        env.value = 'dev'
    }else if(process.env.VUE_APP_API_URL == 'https://kshtest.lanlingcb.com/dsgc'){
        env.value = 'test'
    }else if(process.env.VUE_APP_API_URL == 'https://prellcb.kaishuihu.com/dsgc'){
        env.value = 'gray'
    }else if(process.env.VUE_APP_API_URL == 'https://llcb.kaishuihu.com/dsgc'){
        env.value = 'prod'
    }
    getInfo()
})
const info = ref({})
const getInfo = async () => {
    briefInfo().then(res => {
        info.value = res.data
        shareGetConfig()
    })
}


// 倒计时文本
const countText = computed(() => {
    return counting.value ? `${counter.value}秒后重新发送` : '获取验证码'
})

// 验证手机号
const validatePhone = () => {
    if (!phone.value) {
        showToast('请输入注册手机号')
        return false
    }
    if (!/^1[3456789]\d{9}$/.test(phone.value)) {
        showToast('手机号码有误')
        return false
    }
    return true
}

// 获取验证码
const getSmsCode = async () => {
    if (!validatePhone()) return

    try {
        counting.value = true
        // 这里添加获取验证码的API调用
        sendRegisterSmsCode({ phone: phone.value, type: 5, device: 'h5' }).then(res => {
            startCountdown()
            if (res.code == 1) {
                showToast('验证码发送成功')
            } else {
                showToast(res.msg)
            }
            // startCountdown()
        })
    } catch (error) {
        showToast('获取验证码失败，请稍后重试')
    }
}

// 倒计时
const startCountdown = () => {
    counter.value = 60
    const timer = setInterval(() => {
        counter.value--
        if (counter.value <= 0) {
            counting.value = false
            clearInterval(timer)
        }
    }, 1000)
}
const shareGetConfig = async () => {
    shareConfig({ url: location.href }).then(res => {
        console.log(res)
        if (res.code == 1) {
            wx.config({
                debug: false,
                appId: res.appid,
                timestamp: res.timestamp,
                nonceStr: res.nonceStr,
                signature: res.signature,
                jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage']
            })
            wx.ready(function () {
                wx.updateAppMessageShareData({
                    title: data.title, // 分享标题
                    desc: data.desc, // 分享描述
                    // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                    link: data.url,
                    imgUrl: data.imgUrl, // 分享图标
                    success: () => {
                        // doShareDone()
                    },
                    cancel: () => {
                        // doShareCancel()
                    }
                });
                wx.updateTimelineShareData({
                    title: data.title, // 分享标题
                    desc: data.desc, // 分享描述
                    // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                    link: data.url,
                    imgUrl: data.imgUrl, // 分享图标
                    success: () => {
                        // doShareDone()
                    },
                    cancel: () => {
                        // doShareCancel()
                    }
                });
            });
        }
    })
}
// 提交表单
const handleSubmit = async () => {
    if (!validatePhone()) return

    if (!smsCode.value) {
        showToast('请填写验证码')
        return
    }
    if (smsCode.value.toString().length !== 6) {
        showToast('验证码填写错误')
        return
    }

    if (!agreed.value) {
        showToast('请阅读协议并勾选')
        return
    }
    console.log(env.value,'env.value')
    // 这里添加注册的API调用
    codeRegister({ phone: phone.value, smsCode: smsCode.value, env: env.value, device: 'h5', sourceType: 'ksh_h5_register', version: '2.4.2', notAddFriend: '1' }).then(res => {

        if (res.code == 1) {
            showConfirmDialog({
                title: '去下载APP',
            }).then(res => {
                if (res === 'confirm') {
                    window.location.href = "http://www.kaishuihu.com/download/download.html";
                }
            })

        } else {
            showToast(res.msg)
        }
    })
}
</script>

<style lang="less" scoped>
.bg {
    width: 100%;
    height: 100vh;
    padding-top: 50px;
    background: url('../../../assets/register/login.png') left top no-repeat;
    background-size: 100% 100%;
}
.avatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #fff;
}
.nickname{
    max-width: 90%;
    margin: 0 auto;
}
.content {
    background: url('../../../assets/register/content.png') left top no-repeat;
    background-size: 100% 100%;
    width: 326px;
    height: 348px;
    margin: 0 auto;
    padding-top: 20px;
}

.input {
    width: 90%;
    height: 50px;
    border-radius: 8px;
    padding-left: 10px;
    border: 1px solid #F6D9A5;
    margin: 0 auto;
}

.input::placeholder {
    color: #CBAEAE;
}

.sms-group {
    margin: 12px 0;
}

.sms-button {
    position: absolute;
    right: 26px;
    top: 9px;
    width: 110px;
    height: 32px;
    background-image: linear-gradient(180deg, #FDECCB 0%, #FBE1B7 100%);
    border-radius: 18px;
    color: #DE6741;
    border: none;
    font-size: 12px;
}

.sms-button.disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.submit-button {
    width: 272px;
    height: 60px;
    background: url('../../../assets/register/btn.png') left top no-repeat;
    background-size: 100% 100%;
    border: none;
    margin: 6px auto;
}

.agreement {
    font-size: 12px;
    img{
        width: 16px;
        height: 16px;
    }
}

.agreement a {
    text-decoration: none;
}
</style>